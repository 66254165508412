body {
  font-family: 'Raleway', sans-serif;
  padding-top: 1px;
  color: #000000;
}

.row {
  padding-top: 65px;
}

.visible,
.invisible {
  opacity: 0.0;
  transition: opacity 0.5s ease;
}

.visible {
  opacity: 1.0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #343a40;
}

h1 {
  font-size: 6rem;
  font-weight: 400;
  line-height: 5.5rem;
}

h2 {
  font-size: 3.5rem;
  text-align: center;
}

h3 {
  font-size: 2rem;
}

.heading{
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #343a40;
}

.subheading {
  text-transform: uppercase;
  font-weight: 300;
  font-family: 'Roboto Mono', monospace;
  font-size: 1.35rem;
}

.center{
  text-align: center;
}

.description {
  font-weight: 300;
  font-family: 'Roboto Mono', monospace;
  font-size: 1.25rem;
}

#projectImage:hover {
  transition: .25s;
  transform: scale(1.035);
}

#projectImage {
  transition: .45s;
  width: 100%;
  height: auto;
}

.links {
  font-size: 1.55rem;
}

@media (max-width: 600px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: #343a40;
  }
  h1 {
    font-size: 4rem !important;
    line-height: 5.5rem;
  }
  html, body {
    overflow-x: hidden;
  }
  #projectType{
    text-align: center;
  }
  .heading{
    text-align: center;
  }
  .subheading{
    text-align: center;
  }
  #test {
    /* box-shadow: 0px 5px 5px red; */
    box-shadow: inset 0px 40px 0px 0px red;
    transition: box-shadow .55s ease-in-out;
    color: black;
    font-size: 1.35rem !important;
  }
  .description {
    font-size: 1rem;
  }
}

.name {
  text-shadow:  0px 0px 35px #000000;
}

#viewCode {
  /* box-shadow: 0px 5px 5px red; */
  box-shadow: inset 0px 40px 0px 0px red;
  transition: box-shadow .55s ease-in-out;
  color: black;
  font-size: 1.35rem;
}

#viewCode:hover {
  box-shadow: inset 0 -75px 0 -1px #00ff40;
}

.profession{
  text-shadow: 1px 1px 1px rgb(141, 141, 141);
  font-weight: 600;
}

#stock .fa.fa-circle {
  opacity: 1.0;
  color: rgb(22, 21, 21);
  transition: .6s;
}

#stock:hover .fa.fa-circle {
  opacity: 1.0;
  color: rgb(48, 247, 9);
  transition: .4s;
  transform: scale(1.1);
}

#calender .fa.fa-circle {
  opacity: 1.0;
  color: rgb(22, 21, 21);
  transition: .6s;
}

#calender:hover .fa.fa-circle {
  opacity: 1.0;
  color: #00d9ff;
  transition: .4s;
  transform: scale(1.1);
}

#linkedin .fa.fa-circle {
  opacity: 1.0;
  color: rgb(22, 21, 21);
  transition: .6s;
}

#linkedin:hover .fa.fa-circle {
  opacity: 1.0;
  color: #0059ff;
  transition: .4s;
  transform: scale(1.1);
}

#github .fa.fa-circle {
  opacity: 1.0;
  color: rgb(22, 21, 21);
  transition: .6s;
}

#github:hover .fa.fa-circle {
  opacity: 1.0;
  color: rgb(247, 9, 9);
  transition: .4s;
  transform: scale(1.1);
}

#leetcode .fa.fa-circle {
  opacity: 1.0;
  color: rgb(22, 21, 21);
  transition: .6s;
}

#leetcode:hover .fa.fa-circle {
  opacity: 1.0;
  color: rgb(209, 146, 28);
  transition: .4s;
  transform: scale(1.1);
}

#sideNav .navbar-nav .nav-item .nav-link {
  font-weight: 700;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  #about {
    padding-top: 8rem !important;
    padding-bottom: 10rem !important;
  }

  #sideNav {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 13rem;
    height: 100vh;
  }

  #sideNav .navbar-brand {
    display: flex;
    margin: auto auto 0;
    padding: 0.5rem;
  }

  #sideNav .navbar-brand .img-profile {
    max-width: 10rem;
    border: 0.5rem solid rgba(255, 255, 255, 0.2);
  }

  #sideNav .navbar-collapse {
    display: flex;
    align-items: flex-start;
    flex-grow: 0;
    width: 100%;
    margin-bottom: auto;
  }

  #sideNav .navbar-collapse .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  #sideNav .navbar-collapse .navbar-nav .nav-item {
    display: block;
  }

  #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
    display: block;
  }

  section.resume-section {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  body {
    padding-top: 0;
    padding-left: 10rem;
    padding-right: 2rem;
  }

  #projectImage {
    max-width: 50rem;
  }

  #projectImageColumn {
    min-width: 30rem;
  }
}

section.resume-section {
  border-bottom: 1px solid #8b850c;
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

section.resume-section .resume-item .resume-date {
  max-width: 1rem;
}

@media (min-width: 768px) {
  section.resume-section .resume-item .resume-date {
    min-width: 18rem;
  }
}

.bg-primary {
  background-color: #a32424 !important;
}

.text-primary {
  color: #a32424 !important;
}